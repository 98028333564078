// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { API_ENDPOINT_SYMBOLS, API_ENDPOINT_LOGOUT } from "../data/Endpoints-API";
import { WS_ENDPOINT_PLATFORM_LIVE_FEEDS } from "../data/Endpoints-WS";
import APIMiddleware from "../data/api/Api-Middleware";
import { ws_close_all, ws_create } from "../data/websocket/Websocket-Middleware";
import { useSymbolContext } from "./Symbol-Context";
import { toast } from 'react-toastify';
import io from 'socket.io-client';
import { IdleTimerProvider, useIdleTimer } from 'react-idle-timer';

export const AuthContext = createContext();

export function useAuthContext() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [connectionClosed, setConnectionClosed] = useState(false);

  useEffect(() => {
    let timeIdClose;
    const eventListenerIdClose = document.addEventListener('connectionClosed', (e) => {
      console.log('Connection closed');
      if (e?.detail) {
        // Handle the connection closed event
        timeIdClose = setTimeout(() => {
          setConnectionClosed(true);
        }, 3500);
      }
    });

    const eventListenerIdOpen = document.addEventListener('connectionOpened', (e) => {
      console.log('Connection opened');
      if (e?.detail) {
        // Handle the connection closed event
        setConnectionClosed(false);
      }
    });

    return () => {
      document.removeEventListener('connectionOpened', eventListenerIdOpen);
      document.removeEventListener('connectionClosed', eventListenerIdClose);
      clearTimeout(timeIdClose);
    };
  }, []);

  const [user, setUser] = useState({
    userId: 0,
    userName: "",
    total_deposit: 0,
    total_withdrawal: 0,
    total_margin: 0,
    total_realized_pnl: 0,
    totalUnrealizedGrossPnL: 0,
    tempData: "",
    isAuthorized: false,
  });
  
  const [fontSize, setFontSize] = useState(12);
  const [font, setFont] = useState(useState(() => localStorage.getItem('storedFont') || 'Large'));
  const [fontSizeTrigger, setFontSizeTrigger] = useState('');
  const [fontUpdating, setFontUpdating] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const [selectedAuthSymbol, setAuthSelectedSymbol] = useState('');
  const [selectedAuthSymbolId, setSelectedAuthSymbolId] = useState('');
  const [defaultSelectedSymbol, setDefaultSelectedSymbol] = useState('');
  const [defaultSelectedCategory, setDefaultSelectedCategory] = useState('');
  const [selectedAuthTimeFrame, setAuthTimeFrame] = useState('1m');
  const [authSelectedCategory, setAuthSelectedCategory] = useState(1);
  const [allValidSymbols, setAllValidSymbols] = useState([]);

  //create web-socket instance for the component
  const ws_platform = ws_create();
  const WS_MESSAGE_EVENT_LISTENER = "ws_message_platform";
  // const data = [];

  const [platFromData, setPlatFromData] = useState([[], [], [], [], [], [],[]]); // Initial data state


  const updateData = (index, newData, keepPrevData = false) => {
    setPlatFromData((prevData) => {
      const updatedData = [...prevData];
      if (keepPrevData) updatedData[index] = {...updatedData[index], keepPrevData};
      else updatedData[index] = newData;
      return updatedData;
    });
  };

  // useEffect(()=>{
  //   setFontSize(2);
  //   setFontSizeTrigger('2LD');
  // },[])



  const handleOnIdle = async () => {
    if (user && user.userId != undefined && user.userId > 0) {
      ws_close_all();
      setShowLogoutModal(true)
    }
  };

  const handleOnActive = () => {
    // Optional: Handle what happens when the user becomes active again
    // console.log('User is active');
  };

  const handleOnAction = () => {
    // Optional: Handle what happens on any user action
   // console.log('User action detected');
  };

  // Set idle timeout to 30 minutes
  const { reset } = useIdleTimer({
    timeout: 30 * 60 * 1000,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction
  });



  const fontAdjuster = (e) => {
    setFontUpdating(true);
    const selectedFont = e.target.value;
    if (selectedFont === 'Large') {
      if (font === 'Default') {
        console.log('def: large')
        setFontSize(2);
        setFontSizeTrigger('2LD');
      } else if (font === 'Small') {
        console.log('small: large')
        setFontSize(4);
        setFontSizeTrigger('4LS');
      }
    } else if (selectedFont === 'Default') {
      if (font === 'Large') {
        setFontSize(-2);
        setFontSizeTrigger('-2DL');
      } else if (font === 'Small') {
        setFontSize(2);
        setFontSizeTrigger('-2DS');
      }
    } else if (selectedFont === 'Small') {
      if (font === 'Default') {
        setFontSize(-2);
        setFontSizeTrigger('-2SD');
      } else if (font === 'Large') {
        setFontSize(-4);
        setFontSizeTrigger('-2SL');
      }      
    }
    setFont(selectedFont);
  };


  useEffect(() => {

    if (user && user.userId != undefined && user.userId > 0) {
      getAllValidSymbols();

      // Initialize WebSocket connection
      const socket = io(process.env.REACT_APP_SOCKET_URL, {
        transports: ['websocket'],
      });

      // Handle WebSocket connection
      socket.on('connect', () => {
        console.log('Connected to WebSocket');
       
          socket.emit('joinRoom', user.userId.toString()); // Join the room based on user ID
          console.log(`Joined room for user ${user.userId}`);
      
      });

      // Register custom event listeners
      socket.on('sltpLog', (data) => {
        console.log('Received SLTP log:', data);
        if (data.display) {
          toast.warning(`${data.status}`);
        }
      });

      socket.on('is_liquidate', (data) => {
        console.log('Received is_liquidate log:', data);
        if (data.display) {
          toast.warning(`${data.status}`);
        }
      });

      // Handle WebSocket connection errors
      socket.on('connect_error', (err) => {
        console.error('Connection error:', err);
      });

      // Cleanup WebSocket connection on component unmount
      return () => {
        socket.disconnect();
        console.log('Socket disconnected');
      };
    }
  }, [user?.userId]); 
  useEffect(() => {
    if (authSelectedCategory && selectedAuthSymbol && user && user.userId != undefined && user.userId > 0) {
      if (connectionClosed) setConnectionClosed(false);
      console.log("on load", user);
      // ws_platform.ws_disconnect(WS_ENDPOINT_PLATFORM_LIVE_FEEDS(user?.userId, localStorage.symbol, 10, '100ms', localStorage.timeFrame, localStorage.variantId),
      // WS_MESSAGE_EVENT_LISTENER);
      loadPlatform(user);
    }
  }, [selectedAuthSymbol, selectedAuthTimeFrame, allValidSymbols]);

  const getAllValidSymbols = async () => {
    // get symbols by api
    const response = await APIMiddleware.get(API_ENDPOINT_SYMBOLS(user.userId));
    if (response.data) {

      setAllValidSymbols(response.data);
      setDefaultSelectedSymbol(response.data[0][0].name)
      setDefaultSelectedCategory(response.data[0][0].symbol_category)
    }
  }

  const login = (userObj) => {
    // Implement login logic, e.g., by making an API request to server.
    // If login is successful, set the user.
    setUser(userObj);
    localStorage.setItem('accountManager', 'open-positions-acc');
    // loadPlatform(userObj);
  };

  const updateUserAuthorization = () => {
    user.isAuthorized = !user.isAuthorized;
  };

  const updateUserData = (updatedUserAccountData) => {
    if (user && user.userId != undefined && user.userId > 0) {
      // If the user is logged in, update account data
      setUser(updatedUserAccountData);
    }
  };

  const updateUserData_Local = (value) => {
    setUser((prevUserData) => ({
      ...prevUserData,
      tempData: value,
    }));
  };



  const loadPlatform = async (user) => {
    if (user && user.userId != undefined && user.userId > 0) {
      // get symbols by api
      // const response = await APIMiddleware.get(API_ENDPOINT_SYMBOLS(user.userId));

      // if (defaultSelectedSymbol) {
      // Extracting symbols names
      let selectedSymbolName;
      let selectedCategory;
      let selectedSymbolTimeFrame;
      // const symbolNames = response.data[0].map((symbol) => symbol.name);
      if (localStorage.symbol == null || localStorage.category == null) {
        selectedSymbolName = defaultSelectedSymbol;
        selectedCategory = defaultSelectedCategory;
        console.log(defaultSelectedCategory, "defaultSelectedCategory")
      }
      // else if (selectedAuthSymbol){
      //   selectedSymbolName = selectedAuthSymbol;
      // } 
      else {
        selectedSymbolName = localStorage.symbol;
        selectedCategory = localStorage.category;
      }

      if (localStorage.timeFrame == null) {
        selectedSymbolTimeFrame = selectedAuthTimeFrame;
        // localStorage.setItem('timeFrame', selectedAuthTimeFrame);
      }
      else {
        selectedSymbolTimeFrame = localStorage.timeFrame;
      }

      if (selectedSymbolName) {
        //ws://localhost:8800/platform@BTCUSDT&10&1000ms&1m?id=8&variant_id=1
        //ws://localhost:8800/platform@ETCUSDT&10&100ms&1m?id=0&variant_id=1
        if (localStorage.variantId && localStorage.token) {
          ws_platform.ws_connect(
            WS_ENDPOINT_PLATFORM_LIVE_FEEDS(user?.userId, selectedSymbolName, 10, '100ms', selectedSymbolTimeFrame, localStorage.variantId, selectedCategory, localStorage.token),
            WS_MESSAGE_EVENT_LISTENER
          );
        }

        const handleLiveFeedData = (event) => {
          // Update data based on event detail
          if (event.detail.type === 'symbols') {
            updateData(0, event.detail.updatedSymbolData, false);
          } else if (event.detail.type === 'klines') {
            updateData(1, event.detail, false);
          } else if (event.detail.type === 'depth') {
            updateData(2, event.detail, false);
          } else if (event.detail.type === 'positions') {
            updateData(3, event.detail, event.detail.keepPrevData);
          } else if (event.detail.type === 'orders') {
            updateData(4, event.detail, event.detail.keepPrevData);
          } else if (event.detail.type === 'user_details') {
            updateData(5, event.detail, event.detail.keepPrevData);
          } else if (event.detail.type === "availableLeverage" ) {
            updateData(6, event.detail, false);
          }
        };

        document.addEventListener(WS_MESSAGE_EVENT_LISTENER, handleLiveFeedData);
      } else {
        console.error("SYmbol not selected");
      }
      // }
    }
  };


  const sendDataToServer = (data) => {
    ws_platform.ws_send(
      WS_ENDPOINT_PLATFORM_LIVE_FEEDS(user?.userId, localStorage.symbol
        , 10, '100ms', localStorage.timeFrame, localStorage.variantId, authSelectedCategory),
      JSON.stringify(data)
    );
  };


  const logout = async () => {
    setIsLoggingOut(true);
    const userId=localStorage.userId
    // Implement logout logic, e.g., by clearing user data or revoking tokens.
    try {
      const response = await APIMiddleware.get(API_ENDPOINT_LOGOUT(localStorage.variantId,userId));
      if (response ) {
        await ws_close_all();
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
    
        if (localStorage.variantId != undefined) {
          localStorage.removeItem("variantId");
        }
        setUser(null);
        // document.body.className = "";
        window.location.reload();
        return null;
      }
    } catch (logoutError) {
      console.log('Logout error:', logoutError);
      setIsLoggingOut(false);
      return logoutError.message || 'Logout failed';
    }
  };
  const logoutWithOutRefresh = async() => {
    const userId=localStorage.userId
    // Implement logout logic, e.g., by clearing user data or revoking tokens.
    const response = await APIMiddleware.get(API_ENDPOINT_LOGOUT(localStorage.variantId,userId));
    if (response ) {
      await ws_close_all();
      localStorage.removeItem("token");
    localStorage.removeItem("userId");
 
    if (localStorage.variantId != undefined) {
      localStorage.removeItem("variantId");
        }
    setUser(null);
    // document.body.className = "";
    // setTimeout(() => {
    //   window.location.reload();
    // }, 300);
  }};


  return (
    <AuthContext.Provider
      value={{
        connectionClosed,
        allValidSymbols,
        user,
        login,
        logout,
        logoutWithOutRefresh,
        isLoggingOut,
        updateUserData,
        updateUserData_Local,
        updateUserAuthorization,
        loadPlatform,
        platFromData,
        sendDataToServer,
        selectedAuthSymbol,
        setAuthSelectedSymbol,
        setAuthTimeFrame,
        selectedAuthTimeFrame,
        setAuthSelectedCategory,
        authSelectedCategory,
        fontAdjuster,
        fontSize,
        selectedAuthSymbolId,
        setSelectedAuthSymbolId,
        fontUpdating,
        setFontUpdating,
        fontSizeTrigger,
        defaultSelectedCategory,
        setShowLogoutModal,
        showLogoutModal
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
